import request from "./axios.js";


/**
 * 裁判查询
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getReFereeData(data) {
  return request.get("/api/referee",{params:data} );
}

/**
 * 裁判添加
 *
 * @param {Object} data - 对象数据
 */
export function addReFereeData(data) {
  return request.post("/api/referee", data);
}

/**
 * 裁判查询id
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getReFereeDataId(data,id) {
  return request.get("/api/referee/"+id,{params:data});
}


/**
 * 裁判删除
 *
 * @param {Object} data - 对象数据
 */
export function deleteRefereeDataId(data,id) {
  return request.delete("/api/referee/"+id,{params: data});
}


/**
 * 裁判修改
 *
 * @param {Object} data - 对象数据
 */
export function editRefereeDataId(data,id) {
  return request.put("/api/referee/"+id,data);
}